
/*header-section*/
.header-section {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e1e1e; /* Dark background color */
    color: #ffffff; /* Default text color */
   
}


.row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-bottom: 70px;
    margin-top: 40px;
}

.textwrapper {
    flex: 1;
    padding: 1rem;
    text-align: right;
    border: none;
}

.imagewrapper {
    flex: 1;
    text-align: center;
    padding: 1rem;
    border: none;
    margin-top: 70px;
}

.imagewrapper img {
    max-width: 100%;
    height: 300px;
    border-radius: 30px;
    width: 450px;
}

.title {
    font-size: 4rem;
    color: #CBA4E7;
    margin-bottom: 1rem;
    font-family: sans-serif;
}

.description {
    font-size: 1.25rem;
    line-height: 1.5;
    color: #21f35298; 
    margin-bottom: 1.5rem;
    font-family: sans-serif;
    margin-left: 3rem;
}

.button-group {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    margin-left: 3rem;
}

.explore-btn {
    background-color: #e1c4f5; /* Light pink background color */
    color: #785392; 
    border: none;
    padding: 1.5rem 2rem;
    font-size: 1rem;
    text-decoration: none;
    border-radius: 30px;
    text-align: center; 
}

.performance-btn {
    background-color: transparent;
    color: #00ff00; /* Light green text color */
    border: 2px solid #00ff00; /* Light green border */
    padding: 1.5rem 2rem;
    font-size: 1rem;
    text-decoration: none;
    border-radius: 30px;
    text-align: center; 
}

.explore-btn:hover{
    opacity: 0.8;
    background-color: #fff;
}
.performance-btn:hover {
    opacity: 0.8;
    color: #06a106;
}

/* Large Screen (1025px to 2560px) */
@media (min-width: 1920px) and (max-width: 2560px) {
    .title{
        text-align: left;
        margin-left: 3rem;
        font-size: 5rem;
    }
    .description{
        font-size: 2rem;
    }
}
/* Media Queries for Tablet */
@media (max-width: 1024px) {
    .header-section{
        width: auto;
        overflow-x: hidden;
    }
    .title {
        font-size: 2.5rem; /* Smaller font size for tablets */
    }

    .description {
        font-size: 1rem; /* Smaller font size */
    }

    .performance-btn, .explore-btn {
        width: auto; /* Smaller button width */
        font-size: 1rem; /* Smaller font size */
    }
}

/* Media Queries for Mobile */
@media (max-width: 640px) {
    .header-section {
        height: auto; 
        width: auto;
        overflow-x: hidden;
    }

    .row {
        flex-direction: column; /* Stack elements vertically */
        height: auto; /* Auto height for better flexibility */
        width: auto;
    }

    .textwrapper, .imagewrapper {
        flex: none; 
        width: auto; 
    }
    .textwrapper{
        order: 2;
    }
    .imagewrapper img{
        order: 1;
        width: 100%;
        height: auto;
    }

    .title {
        font-size: 2rem; 
        text-align: left;
        margin-left: 2rem;
    }

    .description {
        font-size: 0.9rem; /* Even smaller font size */
    }

    .performance-btn, .explore-btn {
        width: auto; /* Full width for better accessibility */
        font-size: 1rem; /* Adapt font size */
    }
}



/*privately fund*/
.fund {
    background-color: white;
    width: 100%;
}
.art-container {
    width: 95%;
    padding: 20px;
    margin-right: auto;
    margin-left: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-right: 15px;
    margin-left: 15px;
    gap:30px;
}

.art-col2 {
    flex: 1;
    max-width: 100%;
    height: auto;
    margin-top: 5vh;
    Margin-right: 30px;
}

.art-col {
    Margin-left: 30px;
    flex: 1;
    width: 100%;
    margin-right: 10px;
    height: auto;
    margin-top: 5vh;
    margin-bottom: 5vh;
}
.art-col img{
    width: 40vw;
}

.section-title {
    font-size: 4.5rem;
    margin-bottom: 2rem;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}

.section-subtitle {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    font-family: sans-serif;
    color:#3F007F;
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
}

.art-text{
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-family: sans-serif;
    color: rgba(0, 0, 0, 0.808);
}
/*For larger screens*/
@media (min-width: 1920px) and (max-width: 2560px) {
    .section-title{
        text-align: left;
        margin-left: 3rem;
        font-size: 6rem;
    }
    .section-subtitle{
        font-size: 4rem;
    }
}
/* Media Queries for Tablet */
@media (max-width: 1024px) {
    .fund{
        width: auto;
        overflow-x: hidden;
    }
    .art-container {
        height: auto; /* Adjust height for better fitting on tablets */
        gap: 20px; /* Reduced gap for better spacing on tablets */
    }

    .art-col2, .art-col {
        height: auto; /* Adjust height for better fitting on tablets */
        margin-right: 15px; /* Adjust margins for better spacing */
        margin-left: 15px;
    }
    .art-col img{
        width: 60vw;
    }

    .section-title {
        font-size: 3rem; /* Smaller font size for tablets */
    }

    .section-subtitle {
        font-size: 1.5rem; /* Smaller font size for tablets */
    }

    .art-text {
        font-size: 1.2rem; /* Smaller font size for tablets */
    }
}

/* Media Queries for Mobile */
@media (max-width: 640px) {
    .fund{
        width: auto;
        overflow-x: hidden;
    }
    .art-container {
        flex-direction: column; /* Stack items vertically on mobile */
        height: auto; /* Auto height for better flexibility */
        width: auto;
        gap: 10px; /* Reduced gap for mobile screens */
        padding: 10px; /* Reduced padding for mobile screens */
    }
    .art-col img{
        width: 100%;
    }

    .art-col2, .art-col {
        margin-right: 0; /* Remove right margin */
        margin-left: 0; /* Remove left margin */
        margin-top: 10px; /* Adjust margin for better spacing */
        margin-bottom: 10px;
    }

    .section-title {
        font-size: 2.5rem; /* Smaller font size for mobile */
    }

    .section-subtitle {
        font-size: 1.25rem; /* Smaller font size for mobile */
        margin-bottom: 30px; /* Adjust bottom margin for mobile */
    }

    .art-text {
        font-size: 1rem; /* Smaller font size for mobile */
    }
}





/*carousel*/
.events-portfolio {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    height: auto; 
}

.portfolio-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
    margin: auto;
}

.portfolio-text {
    width: 35%;
    padding-right: 70px;
}

.portfolio-text h4 {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: black;
    font-family: sans-serif;
}

.portfolio-text h5 {
    font-size: 1.5rem;
    color: rgb(82, 82, 82);
    line-height: 1.5;
    font-family: sans-serif;
    text-align: left;
}

.slider-container2 {
    width: 40%;
    position: relative; 
    height: auto;
}
.embla4 {
    overflow: hidden;
    width: 100%;
   
}

.embla__viewport4 {
    width: 100%;
    display: flex;
    flex-direction: row; 
    margin: 1rem;
}

.embla__slide4  {
    position: relative;
    flex: 0 0 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150vh;
}
.portfolio-card{
    margin: 40px 3rem; 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 500px;
}

.event-card__slide-image img {
    width: 90%;
    display: block;
    border-radius: 20px 20px 0 0;
    height: auto;
    background-color: black;
    background-size: cover;
    background-position: center;
}

.slide-content {
    position: absolute;
    bottom: 20px;
    color: white;
    padding: 10px;
    border-radius: 0 0 20px 20px;
    width: 85%;
    height: 25%;
    background-color: black;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
}

.slide-content h2 {
    font-size: 2rem;
    margin-top: 30px;
    color: #8bf8a4fd;
   
}

.manage-button {
    background-color: #8bf8a4fd; 
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 50px;
    border-radius: 40px;
    cursor: pointer;
    font-size: 1.3rem;
    text-decoration: none;
    color: #000;
}
.manage-button:hover {
    background-color:#b4f8c4fd;
}

.carousel-button-prev, .carousel-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgb(182, 180, 180);
    color: black;
    border: none;
    padding: 15px 15px;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 50%;
    z-index: 2;
}

.carousel-button-prev {
    left: -50px; 
}

.carousel-button-next {
    right: -50px; 
} 

/* Large Screen (1025px to 2560px) */
@media (min-width: 1920px) and (max-width: 2560px) {
    .portfolio-text h4 {
        font-size: 4.5rem; 
    }

    .portfolio-text h5 {
        font-size: 3rem; 
    }
    .slider-container2 {
        width: 60vh; /* Full width for slider */
        margin-top: 0;
        height: auto;
    }
    .embla__slide4  {
        height: 50vh;
    }

    .event-card__slide-image img {
        width: 45vh; 
        height: auto; 
    }

    .slide-content {
        width: 43vh; 
        height: auto; 
        padding: 20px; 
        margin-left: 120px;
    }
    .slide-content h2 {
        font-size: 2.5rem; 
    }
    
    .manage-button {
        padding: 12px 24px; 
        font-size: 2rem; 
    }

    .carousel-button-prev {
        padding: 20px 30px; 
        left: 10px; 
        font-size: 3rem;
    }
    .carousel-button-next{
        right: 10px;
        padding: 20px 30px; 
        font-size: 3rem;
    }
}
/*Tablet styles*/
@media (min-width: 641px) and (max-width: 1024px) {
    .events-portfolio{
        overflow: hidden;
        height: auto;
        width: auto;
    }
    .portfolio-container {
        flex-direction: row;
        align-items: center;
    }

    .portfolio-text {
        width: 30%; /* Wider text area on tablets */
        padding-right: 0; /* Remove padding when stacked */
        text-align: left; /* Center text for better readability */
    }

    .portfolio-text h4 {
        font-size: 2.5rem; /* Slightly smaller font size on tablets */
    }

    .portfolio-text h5 {
        font-size: 1.3rem; /* Adjust font size for tablet readability */
    }
    .slider-container2 {
        width: 60%; /* Full width for slider */
        margin-top: 0;
    }
    .event-card__slide-image img {
        width: 85%; /* Full width images on mobile */
        height: auto; /* Auto height for proper aspect ratio */
    }

    .slide-content {
        width: 75%; 
        height: 20%; 
        padding: 20px; 
        font-size: 1.1rem; 
    }

}

/*mobile styles*/
@media (max-width: 640px) {
    .events-portfolio{
        overflow: hidden;
        height: auto;
        width: auto;
    }
    .portfolio-container {
        flex-direction: column;
        align-items: center;
        width: 90%; /* Use more of the screen width */
    }

    .portfolio-text {
        width: 100%; /* Full width for text block */
        padding: 20px; /* Add padding for spacing */
        text-align: center; /* Center align the text */
    }

    .portfolio-text h4 {
        font-size: 2rem; /* Reduce font size for mobile */
    }

    .portfolio-text h5 {
        font-size: 1.2rem; 
        margin-bottom: 0;
    }

    .slider-container2 {
        width: 90%; /* Full width for slider */
        margin-top: 0;
    }

    .embla__slide4 {
        flex-direction: column;
        margin-top: 0;
    }

    .event-card__slide-image img {
        width: 85%; /* Full width images on mobile */
        height: auto; /* Auto height for proper aspect ratio */
    }

    .slide-content {
        width: 75%; 
        height: 20%; 
        padding: 20px; 
        font-size: 1.1rem; 
    }

    .manage-button {
        padding: 12px 24px; /* Adjust button padding for easier tapping */
        font-size: 1.1rem; /* Slightly smaller font size */
    }

    .carousel-button-prev {
        padding: 10px 20px; /* Adjust control button size for mobile */
        left: 0; /* Adjust button positioning */
    }
    .carousel-button-next{
        right: 10px;
        padding: 10px 20px; 
    }
}



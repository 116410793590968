/* login.css */
body{
  background-color: #F4F2EE;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  height:auto;
  padding: 20px;
  background-color: #91949c;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 100px;
  margin-bottom: 70px;
  margin-left: auto;
  margin-right: auto;
}

.header {
  margin-bottom: 30px;
  margin-top: 30px;
}
.signup-text{
  text-align: center;
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: #9df9aa;
  font-size: 18px;
}
.text {
  font-size: 40px;
  font-weight: bold;
  color: #9df9aa;
  font-family: sans-serif;
}

.inputs {
  width: 100%;
}

.input {
  margin-bottom: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input input {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-sizing: border-box;
  height: 50px;
}

.forgot-password a {
  margin-bottom: 15px;
  color: #9df9aa;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;

}
.forgot-password{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.forgot-password a:hover{
  color: #bef0c4b7;
}
.loginsinput{
  display: flex;
  gap: 30px;
  text-align: center;
  
}
.login-signup {
    display: flex;
    flex-direction: row;
    align-items: center; /* Center align the buttons */
    gap: 5px; /* Reduced gap for closer button placement */
    margin-top: 30px;
}

.button-login {
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.button-login:hover {
  background-color: #bef0c4b7;
}
.button-login, .button-switch {
  width: 100%; /* Full width for both buttons */
  padding: 10px; /* Adequate padding for better clickability */
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1.3rem; /* Ensure text is comfortably readable */
  transition: all 0.3s ease; /* Smooth transition for interactions */
  color: black;
  margin-bottom: 30px;
  font-weight: bold;
  font-family: Arial, sans-serif;
}

.button-login {
  background-color: #9df9aa; /* Example blue color */
}

.button-switch {
  background-color: #f0f0f0; 
  margin-top: 5px; 
}

.button-switch:hover {
  background-color: #e0e0e0; /* Slightly darker on hover */
}
.togglepassword {
  position: absolute;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  margin-right: 10px; 
  right: 0;
  top: 20px;
}


/* Media Queries for Tablet */
@media (max-width: 1024px) {
.container {
  padding: 15px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.text {
  font-size: 2rem; /* Smaller text for tablets */
}

.input input, .button-login, .button-switch {
  font-size: 1rem; /* Smaller buttons and input text */
}
}

/* Media Queries for Mobile */
@media (max-width: 640px) {
.container {
  padding: 10px;
  margin-top: 120px;
  margin-bottom: 30px;
  width: auto;
  margin-left: 20px;
  margin-right: 20px;
}

.text {
  font-size: 1.5rem; /* Even smaller text for mobiles */
}

.input input, .button-login, .button-switch {
  font-size: 0.9rem; /* Even smaller buttons and input text */
}

.loginsinput {
  flex-direction: column; /* Stack elements vertically */
  gap: 10px;
}
}


/*google login*/
.S9gUrf-YoZ4jf{
  display: block;
  margin: auto;
}
.attendevent{
  background-color: black;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.attend-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1200px; 
  margin-top: 70px;
}

.attend-card {
  display: flex;
  align-items: center;
  background-color: #f0f0f0; 
  border-radius: 30px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-top: 30px;
  overflow: hidden; 
  width: 1100px;
  height: 55vh;
}

.attend-card-row {
  flex: 2; 
  max-width: 40%; 
}

.attend-card-row img {
  width: 100%; 
  height: 55vh; 
  display: block;
  border-radius: 30px;
}

.attend-card-text {
  flex: 3; 
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: flex-start; 
}

.attend-card-text h5, .attend-card-text h6, .attend-card-text p {
  margin: 15px 0 15px 0;
  font-family: sans-serif;
  text-align: left;
}
.attend-card-text h5{
  font-weight: bold;
  color: black;
  font-size: 2rem;
}
.attend-card-text h6{
  color: rgb(70, 69, 69);
  font-size: 1.5rem;
}
.attend-card-text p{
  color: rgb(105, 104, 104);
  font-size: 1rem;
}
.attend-card-button a {
  display: inline-block; 
  padding: 20px 30px; 
  background-color: #7efa91; 
  color: grey; 
  text-decoration: none; 
  border-radius: 30px; 
  transition: background-color 0.3s ease; 
  font-weight: bold; 
  text-align: center; 
  margin-top: 20px;
  margin-bottom: 10px;
}

.attend-card-button a:hover {
  background-color: #b2f7bc; /* Darker shade on hover for better interaction feedback */
}

.attend-card-button a:active {
  background-color: #b2f7bc; /* Even darker when the button is pressed */
  color: #ccc; /* Light grey text to indicate active state */
}
.eventserror-message{
  font-size: 1.5rem;
  font-family: sans-serif;
  text-align: center;
  color: red;
}


/* Tablet Screen (641px to 1024px) */
@media (max-width: 1024px) {
.attend-card {
    flex-direction: column; /* Stack elements vertically for better readability */
    height: auto;
    width: auto;
}

.attend-card-row {
    max-width: 100%; /* Use full width */
}

.attend-card-text {
    align-items: center; /* Center align the text block */
}
}

/* Mobile Screen (up to 640px) */
@media (max-width: 640px) {
.attend-card {
    margin-left: 10px;
    margin-right: 10px; /* Reduce margin for side spacing */
    height: auto;
    width: auto;
}
.attend-card-row {
  max-width: 100%; /* Use full width */
}

.attend-card-row img {
    height: 45vh; /* Adjust height for mobile screens */
    width: 100%;
}

.attend-card-text h5 {
    font-size: 2rem; /* Smaller font size for headers */
}

.attend-card-text h6 {
    font-size: 1.5rem; /* Smaller font size for sub-headers */
}

.attend-card-text p {
    font-size: 1.2rem; /* Smaller font size for paragraph text */
}
.attend-card-row {
  flex-direction: column;
}
}

/*funding art*/
.Funding-art{
  background-color: black;
  width: 100%;
  height: auto;
}
.Container-row{
  display: flex;
  flex-wrap: wrap;
  margin-right: 15px;
  margin-left: 15px;
  gap:30px;
  height: auto;
  
}
.Funding-text{
  flex: 1;
  width: 100%;
  margin-right: 10px;
  margin-left: 70px;
  height: auto;
  margin-top: 70px;
  margin-bottom: 5vh;
}
.Funding-image{
  flex: 1;
  width: auto;
  height: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-right: 70px;
}
.Funding-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header{
  font-size: 4rem;
  margin-bottom: 2rem;
  font-family: sans-serif;
  font-weight: bold;
  text-align: center;
  margin-top: 20px; 
  color: rgba(58, 241, 113, 0.5);
}
p{
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  font-family: sans-serif;
  text-align: left;
  margin-bottom: 50px;
  color: rgba(58, 241, 113, 0.5);
}
.button-group a {
  text-decoration: none; 
  padding: 20px 10px;
  border-radius: 40px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.7rem;
  display: flex;
  justify-content: center;
}

.button-members {
  flex: 1;
  background-color: rgba(58, 241, 113, 0.5); 
  color: white;
}

.button-event {
  flex: 1;
  background-color: black; 
  color: white;
  border: solid white;
}
.button-members:hover {
  background-color: rgba(42, 245, 103, 0.747);
}
.button-event:hover {
  color: rgb(128, 128, 128);
}
/* Tablet Screen (641px to 1024px) */
@media (max-width: 1024px) and (min-width: 641px) {
.Funding-art{
  width: auto;
  height: auto;
}
.header {
    font-size: 3rem;
}
.p {
    font-size: 1.2rem;
}
.Container-row{
  display: flex;
  margin-right: 15px;
  margin-left: 15px;
  gap:30px;
  height: auto;
  width: auto;
}
.Funding-text{
  flex: 1;
  width: auto;
  margin-right: 10px;
  margin-left: 70px;
  height: auto;
  margin-top: 70px;
  margin-bottom: 5vh;
}
.Funding-image{
  flex: 1;
  width: auto;
  height: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-right: 70px;
}
.Funding-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.button-group a {
padding: 15px 8px;
font-size: 0.8rem;
text-align: center;
}
}

/* Mobile Screen (up to 640px) */
@media (max-width: 640px) {
.Funding-art{
  width: auto;
  height: auto;
}
.Container-row {
    flex-direction: column;
}
.header {
    font-size: 2rem;
    margin-top: 10px;
    margin-bottom: 1rem;
}
.Funding-text {
    margin: auto;
    height: auto;
    margin-top: 70px;
}
.Funding-image{
  margin-bottom: 20px;
  margin: auto;
  height: auto;
}
.p {
    font-size: 1rem;
}
.button-group a {
  padding: 10px 6px;  
  font-size: 1.2rem;
  text-align: center;  
}
.button-members, .button-event {
  width: 100%;  
}
}



/*whyfund*/
.whyfund{
  background-color: rgb(218, 216, 216);
  width: auto;
  height: auto;
  padding: 20px;
  margin: 0 auto; 
}
.whyfund-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
  height: auto;
  
}

.column {
  flex: 1; 
}


.column-right {
  max-width: 50%;
  border: none;
  flex:1;
  margin-right: 30px;
  padding: 10px;
  text-align: center;
  height: auto;
}

.cardWrapper {
  flex:1;
  padding: 20px;
  background-color: black; 
  margin-left: 30px;
  height: auto;
  border-radius: 30px;
  margin-bottom: 30px;
  width: auto;
}

.sectionCardTitle {
  font-size: 3.5rem;
  text-align: center;
  color: #85f895;
  font-family: sans-serif;
  margin-top: 2rem;
}

.cardText {
  font-size: 2rem;
  font-family: sans-serif;
  color: white;
}

.sectionTitle {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: black;
  font-family: sans-serif;
}

.sectiontext {
  font-size: 1.5rem;
  color: black;
  font-family: sans-serif;
}

.btnmember {
  padding: 10px 20px;
  background-color: #85f895;
  color: black;
  text-decoration: none;
  border-radius: 30px;
  padding: 20px 20px;
}

.btnevents {
  padding: 10px 20px;
  border: 2px solid black;
  color: rgba(0, 0, 0, 0.76);
  background-color: transparent;
  text-decoration: none;
  border-radius: 30px;
  padding: 20px;
}

.sectionButtons {
  margin-top: 20px;
  display: flex;  
  justify-content: center;
  gap: 30px;      
  font-size: 20px;
  font-weight: bold; 
}

.btnmember:hover {
  opacity: 0.8;
}
.btnevents:hover{
  color: black;
}
/* Tablet Screen (641px to 1024px) */
@media (max-width: 1024px) and (min-width: 641px) {
.whyfund{
  height: auto;
}
.column-right, .cardWrapper {
    max-width: 100%;
    margin: 0 10px;
    height: auto;  /* Adjust height for better readability */
}
.sectionCardTitle, .sectionTitle {
    font-size: 3rem;  /* Slightly smaller font size */
}
.cardText, .sectiontext {
    font-size: 1.7rem;  /* Adjust text size for better readability */
}
}

/* Mobile Screen (up to 640px) */
@media (max-width: 640px) {
.whyfund{
  height: auto;
}
.whyfund-row, .sectionButtons {
    flex-direction: column;  /* Stack elements vertically */
}
.column, .column-right, .cardWrapper {
    max-width: 100%;
    margin: 10px 0;
    padding: 10px;
    height: auto;  /* Allow content to adjust height dynamically */
}
.sectionCardTitle, .sectionTitle {
    font-size: 2rem;  /* Reduce font size for mobile screens */
}
.cardText, .sectiontext {
    font-size: 1.2rem;  /* Smaller font sizes for better fit */
}
.btnmember, .btnevents {
    width: 100%;  /* Full width buttons for easier access */
    padding: 15px 10px;  /* Adjust padding for smaller screens */
}
}





/*finacial*/
.financial{
  background-color: black;
  width: 100%;
  height: auto;
}
.featureRow {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 80px;
}
.featureHeading h4{
text-align: center;
margin-bottom: 20px;
margin-top: 40px;
color: #85f895;
font-size: 4.5rem;
font-family: sans-serif;
font-weight: bold;
padding: 40px;
margin: auto;
}
.featureItem {
  margin-bottom: 16px;
  flex: 1 1 30%; /* Adjusts to take full width on smaller screens and 30% on larger screens */
  max-width: 30%; /* Ensures no item becomes wider than 30% */
  background-color: black;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 40px;
}
.image {
  width: 100px;
  height: 100px;
  margin-top: 50px;
}

.cardTitle {
  color: #fff; 
  font-size: 25px; 
  font-weight: bold;
  font-family: sans-serif;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 30px;
}
/* Tablet Screen (641px to 1024px) */
@media (max-width: 1024px) and (min-width: 641px) {
.featureRow {
    padding: 50px; /* Reduced padding for more space */
}
.featureItem {
    flex: 1 1 45%; /* Increase width to 45% for better fit */
    max-width: 45%; /* Adjust maximum width */
}
.cardTitle {
    font-size: 20px; /* Smaller font size for better fitting */
}
}

/* Mobile Screen (up to 640px) */
@media (max-width: 640px) {
.featureRow {
    padding: 20px; /* Further reduced padding for small screens */
}
.featureItem {
    flex: 1 1 100%; /* Full width for mobile */
    max-width: 100%; /* Use the full width available */
}
.cardTitle {
    font-size: 18px; /* Further reduce the font size */
    margin-top: 20px; /* Reduced margin */
    margin-bottom: 20px; /* Reduced margin */
}
.image {
    width: 80px; /* Smaller image size */
    height: 80px; /* Smaller image size */
    margin-top: 20px; /* Reduced margin */
}
}




/*indie-team
.container-indie{
  background-color: white;
  width: 100%;
}
.section-head-indie {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 20px;
  color: #254F1A;
  font-size: 60px;
  font-family: sans-serif;
  font-weight: bold;
}

.sectionrow {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin: 0 20px;
  height: auto;
}

.sectioncolumn {
  flex: 1;  
  background-color: white;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: auto;
  height: auto;
  margin-bottom: 30px;
}

.item-img img {
  width: 350px;
  height: 350px;
  margin-bottom: 10px;
  border-radius: 30px;
}

.item-content h5 {
  font-size: 25px;
  margin-bottom: 5px;
  color: #D70081;
}

.item-content h6 {
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 10px;
  color: #023602;
}

.item-content p {
  font-size: 20px;
  color: #383838;
}
/* Tablet Screen (641px to 1024px) 
@media (max-width: 1024px) {
.section-head-indie {
    font-size: 40px; 
}
.sectioncolumn {
    flex: 1 1 45%; 
}
.item-img img {
    max-width: 300px; 
}
}

/* Mobile Screen (up to 640px) 
@media (max-width: 640px) {
.sectionrow {
    margin: 0 10px; 
}
.sectioncolumn {
    flex: 1 1 100%; 
    box-sizing: border-box;
}
.section-head-indie {
    font-size: 30px; 
}
.item-img img {
    max-width: 100; 
}
.item-content h5 {
    font-size: 20px; 
}
.item-content h6 {
    font-size: 25px; 
}
.item-content p {
    font-size: 18px; 
}
} */


/*questions*/
.questions-container {
  background-color: black;
  width: 100%;
  height: auto;
  padding-bottom: 40px; /* Added padding to bottom for spacing */
}

.questions-head h3 {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 40px;
  color: #fff;
  font-size: 50px;
  font-family: sans-serif;
  font-weight: bold;
  padding: 40px;
}

.questions-row {
  display: flex;
  flex-direction: column;
  align-items: center; 
  width: 90%; 
  margin: auto; /* Center the container horizontally */
}

.accordion {
  border-radius: 30px; 
  margin: 10px auto; 
  width: 80%; 
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); 
}

.accordion-header {
  background-color: #85f895; /* Adjusted green background */
  color: black;
  padding: 40px; /* Increased padding for larger click area */
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Full width of its container */
  font-size: 35px; /* Larger text for better readability */
  border-radius: 20px 20px 0 0; /* Rounded corners */
  font-weight: bold;
}

.accordion-icon {
  margin-left: 10px; 
}

.accordion-content {
  padding: 10px; 
  display: none; 
  background-color:  #85f895;
  border-top: none; /* No border at the top to maintain smooth transition */
  border-radius: 0 0 20px 20px; /* Rounded corners on bottom only when open */
}
.accordion-content p {
font-size: 20px;
color: black; 
}
.accordion-content.open {
  display: block; 
  color: black;
}
/* Tablet Screen (641px to 1024px) */
@media (max-width: 1024px) {
.questions-head h3 {
    font-size: 40px; /* Slightly smaller font size for tablets */
    padding: 20px; /* Reduced padding */
}

.accordion-header {
    padding: 30px; /* Slightly less padding for smaller screens */
    font-size: 30px; /* Adjust font size for better fit */
}

.accordion {
    width: 90%; /* Slightly wider to use more screen space */
}
}

@media (max-width: 640px) {
.questions-head h3 {
    font-size: 30px; /* Even smaller font size for mobile */
    padding: 10px; /* Less padding to save space */
}

.accordion-header {
    padding: 20px; /* Reduced padding for compactness */
    font-size: 25px; /* Smaller font size for headers */
    display: flex;
    justify-content: space-between; /* Ensures space distribution between elements */
    align-items: center; /* Centers items vertically */
}

.accordion-icon {
    transform: scale(0.8); /* Reduce the size of the icon to fit better */
    margin-right: 10px; /* Ensure some space on the right */
}

.accordion {
    width: 100%; /* Use full width available */
    box-sizing: border-box; /* Include padding and borders in the element's width */
}

.accordion-content p {
    font-size: 18px; /* Smaller text for compact display */
}
.accordion-text{
  font-size: 20px;
}

.accordion-content {
    padding: 15px; /* Adjust padding to ensure it does not push content too wide */
}
}



.password-reset-container {
    width: auto;
    height: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4F2EE;
}

.password-reset-row {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 40px;
    background-color: #91949c;
}
.password-reset-row h1 {
    color: #9df9aa;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 40px;
}
.password-reset-input {
    width: 90%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #ccc;
    height: 35px;
}

.password-reset-button {
    width: 40%;
    padding: 10px 20px;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: black;
    background-color: #9df9aa;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    height: 45px;
}
.password-reset-button:hover {
    background-color: #bef0c4b7;
  }
  .message {
    color: #9df9aa; /* Green for success messages */
    text-align: center;
    font-size: 14px;
    padding: 5px;
}

.error-message {
    color: #d8000c; /* Red for error messages */
    text-align: center;
    font-size: 14px;
    padding: 5px;
}

@media (max-width: 1024px) {
    .password-reset-container {
        width: auto;
        overflow-x: hidden;
    }
    .password-reset-row {
        width: 90%;
        height: auto;
    }

    .password-reset-input {
        font-size: 1.1rem;
        height: 40px;
    }

    .password-reset-button {
        width: 45%;
        font-size: 1.1rem;
        height: 45px;
    }
}

@media (max-width: 640px) {
    .password-reset-container {
        width: auto;
        overflow-x: hidden;
    }
    .password-reset-row {
        width: 90%;
        padding: 15px;
        margin-top: 120px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .password-reset-input {
        width: 80%;
        font-size: 1rem;
        height: 35px;
    }

    .password-reset-button {
        width: 40%;
        font-size: 1rem;
        height: 40px;
    }
}



/*new password*/
.newpassword-container{
    width: auto;
    height: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4F2EE;
}
.newpassword{
    width: 100%;
    max-width: 600px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 10px;
    background-color: #91949c;
}
.newpassword h2{
    color: #9df9aa;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 40px;  
}
.newpassword-form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    width: 40vw;
    justify-content: center;
    align-items: center;
}
.newpassword-input{
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #ccc;
    height: 35px;
}
.newpassword-button{
    width: 50%;
    padding: 10px 20px;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: black;
    background-color: #9df9aa;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    height: 55px;
}
.newpassword-button:hover {
    background-color: #bef0c4b7;
  }
.newpassword-message{
    color: #318000;
    background-color: #ffd2d2;
    padding: 5px;
    border-radius: 4px;
    text-align: center;
}
.password-field {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.toggle-password {
    position: absolute;
    right: 0;
    top: 20px;
    cursor: pointer;
    color: #333;
    font-size: 20px; 
}